<template>
  <vuestic-widget>
    <template slot="header">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <span class="title">Numbers</span>
        <div class="d-flex justify-content-end align-items-center">
          <button class="btn btn-primary btn-sm mr-2" @click="onRefresh()" :disabled="loading">
            <span>Refresh <i class="fa fa-refresh"></i></span>
          </button>
        </div>
      </div>
    </template>
    <div class="row">
      <div class="col-md-12">
        <div class="d-flex justify-content-center" v-if="numberFetching">
          <span class="atom-spinner-wrapper">
            <atom-spinner slot="loading" :animation-duration="1500" :size="120" color="rgb(53,120,198)" />
          </span>
        </div>
        <div v-else>
          <datatable v-bind="this.tableConfig" class="le-datatable" />
        </div>
      </div>
    </div>
  </vuestic-widget>
</template>

<script>
import Vue from 'vue';
import components from "../../common/tables/comps";
import More from './Actions/More'
import VerificationStatus from './Actions/VerificationStatus'
import { mapState } from 'vuex';

export default {
  components: {},
  data() {
    return {
      isEdit: false,
      loading: false,
      isOpenModalEdit: false,
      isOpenModalDelete: false,
      tableConfig: {
        supportBackup: false,
        supportNested: false,
        HeaderSettings: false,
        tblClass: "table-bordered",
        tblStyle: "color: #666",
        pageSizeOptions: [10, 25, 50, 100],
        columns: (() => {
          return [
            { title: "Name", field: "name", },
            { title: "Phone Number", tdComp: 'TdPhone', field: "number", },
            { title: "Type", field: "typeStr", tdClass: 'center' },
            { title: "Verification Status", tdComp: VerificationStatus},
            { title: "Forwarding Number", tdComp: 'TdPhone', field: "forward_number", },
            { title: "Timeout", field: "timeout_label", },
            { title: '', tdComp: More, visible: 'true', tdClass: 'center' },
          ];
        })(),
        data: [],
        total: 0,
        // selection: [],  //if this is present the row selector shows up..
        summary: {},
        query: {},
        // any other staff that you want to pass to dynamic components (thComp / tdComp / nested components)
        xprops: {
          eventbus: new Vue()
        }
      },
      submitDataOrg: {
        id: null,
        name: ''
      },
      submitData: {
        id: null,
        name: ''
      }
    };
  },

  created() {
    this.submitData = Vue.util.extend({}, this.submitDataOrg)
    this.tableConfig.xprops.eventbus.$on('openEditModal', row => {
      this.isEdit = true
      this.submitData = {
        id: row.id,
        name: row.name
      }

      this.isOpenModalEdit = true
    })
    this.tableConfig.xprops.eventbus.$on('openDeleteModal', row => {
      this.submitData = {
        id: row.id,
        name: row.name
      }
      this.isOpenModalDelete = true
    })

    const offset = (this.numberPage - 1 >= 0 ? this.numberPage - 1 : 0) * this.numberPerPage;
    this.tableConfig.query = {
      offset,
      limit: this.numberPerPage,
    }
  },

  watch: {
    numbers: {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.tableConfig.data = newValue;
        }
      },
      deep: true,
    },
    numbersTotal: {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.tableConfig.total = newValue;
        }
      },
      deep: true,
    },
    'tableConfig.query': {
      handler() {
        this.loadNumbers();
      },
      deep: true
    }
  },

  computed: {
    ...mapState('number', {
      numberFetching: 'fetching',
      numberDidFetch: 'didFetch',
      numberProcessing: 'processing',
      numberPage: 'page',
      numberPerPage: 'perPage',
      numberFilter: 'filter',
      numbers: 'data',
      numbersTotal: 'total'
    })
  },
  mounted() {
  },
  methods: {
    onRefresh() {
      this.loadNumbers()
    },
    
    loadNumbers() {
      const { query } = this.tableConfig;
      const page = query.offset ? Math.floor(query.offset / query.limit) + 1 : 1;
      const param = {
        page,
        per_page: query.limit,
        order: query.order,
        sort: query.sort
      }

      this.$store.dispatch('number/fetch', param)
    },

    onFormSubmit() {
      if (this.isEdit) {
        const param = {
          id: this.submitData.id,
          data: this.submitData
        }
        this.$store
          .dispatch('number/update', param)
          .then(() => {
            this.isOpenModalEdit = false;
            this.loadNumbers();
          })
          .catch((err) => {
          })
      } else {
        const param = {
          name: this.submitData.name
        }
        this.$store
          .dispatch('number/create', param)
          .then(() => {
            this.isOpenModalEdit = false;
            this.loadNumbers();
          })
          .catch((err) => {
          })
      }
    },

    openModalCreate() {
      this.submitData = Vue.util.extend({}, this.submitDataOrg)
      this.isEdit = false
      this.isOpenModalEdit = true
    },

    closeModalEdit() {
      this.isOpenModalEdit = false
    },

    closeModalDelete() {
      this.isOpenModalDelete = false
    },

    onDeleteNumber() {

      this.$store
        .dispatch('number/delete', this.submitData.id)
        .then(() => {
          this.isOpenModalDelete = false;
          this.loadNumbers();
        })
        .catch((err) => {
        })
    },
  },

};
</script>

<style lang="scss" scoped>
.restricted-keyword-list {
  span {
    display: inline-block;
    margin-right: 5px;
  }
}
</style>
